import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Controls } from './Common'

import TextInput from 'components/Common/Form/Input/TextInput'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TextLink from 'components/Luxkit/TextLink'
import SearchMenuListItem from 'components/Search/SearchForm/SearchMenu/SearchMenuListItem'
import {
  TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES,
  TOUR_V2_SEARCH_TYPES,
} from 'constants/tours'
import {
  GlobalSearchDispatchContext,
  GlobalSearchStateContext,
} from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import useGlobalSearchTypeahead from 'hooks/GlobalSearch/useGlobalSearchTypeahead'
import { TRIP_EXPERIENCE_TYPEAHEAD_SEARCH_TYPES } from 'tripPlanner/config/search'
import { useTripDestinations } from 'tripPlanner/hooks/api/trip'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'

type SearchViewType =
  | 'ACCOMMODATION'
  | 'ATTRACTION'
  | 'TOUR_AND_CRUISES'
  | 'EXPERIENCE'
  | 'DINING'

// LocationInput.tsx seems to recieve no typeaheadTypes and 'destination' as a searchType
const SearchTypes: Record<
  SearchViewType,
  {
    searchTypes: Array<App.SearchType>
    typeaheadTypes: Array<App.SearchPlaceType>
  }
> = {
  ACCOMMODATION: {
    searchTypes: [],
    typeaheadTypes: [],
  },
  ATTRACTION: {
    searchTypes: TRIP_EXPERIENCE_TYPEAHEAD_SEARCH_TYPES,
    typeaheadTypes: [],
  },
  DINING: {
    searchTypes: TRIP_EXPERIENCE_TYPEAHEAD_SEARCH_TYPES,
    typeaheadTypes: [],
  },
  EXPERIENCE: {
    searchTypes: TRIP_EXPERIENCE_TYPEAHEAD_SEARCH_TYPES,
    typeaheadTypes: [],
  },
  TOUR_AND_CRUISES: {
    searchTypes: TOUR_V2_SEARCH_TYPES,
    typeaheadTypes: TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES,
  },
}

interface Props {
  tripId: string
  searchView: SearchViewType
}

function SearchPlace({ searchView, tripId }: Props) {
  const tripDestinations = useTripDestinations({ tripId })

  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const { suggestedSearchItems, popularDestinations } = useContext(
    GlobalSearchStateContext,
  )
  const inputRef = useRef<HTMLInputElement>(null)

  const { closeModal, goBack } = useModalCallbacks()

  const { isLoading } = useGlobalSearchTypeahead({
    search: searchPhrase,
    searchTypes: SearchTypes[searchView]?.searchTypes,
    typeaheadTypes: SearchTypes[searchView]?.typeaheadTypes,
  })

  const onClear = useCallback(() => {
    globalSearchDispatch({
      type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS,
      searchItems: [],
    })
  }, [globalSearchDispatch])

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchPhrase(e.currentTarget.value)
    },
    [],
  )

  const onLocationChange = useCallback(
    (changedSearchItem: App.SearchItem) => {
      setSearchPhrase(changedSearchItem.format.mainText)
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_SEARCH_ITEM,
        searchItem: changedSearchItem,
      })

      if (goBack) {
        goBack()
      }
    },
    [globalSearchDispatch, goBack],
  )

  useEffect(() => {
    inputRef.current?.focus()

    return () => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_SUGGESTED_SEARCH_ITEMS,
        searchItems: [],
      })
    }
  }, [globalSearchDispatch])

  return (
    <>
      <ModalHeader
        title="Where are you going?"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      >
        <Controls>
          <TextInput
            ref={inputRef}
            placeholder="Search by destination"
            value={searchPhrase}
            onChange={onInputChange}
            noValidationMessage
            noValidationSpacing
          />
          {searchPhrase.length > 1 && (
            <TextLink
              style={{ width: 'initial' }}
              variant="default"
              onClick={onClear}
            >
              Clear
            </TextLink>
          )}
        </Controls>
      </ModalHeader>
      <ModalBody>
        <ModalContent>
          {!isLoading &&
            suggestedSearchItems.map((item) => (
              <SearchMenuListItem
                key={item.value}
                searchItem={item}
                onClick={onLocationChange}
              />
            ))}
          {searchPhrase.length <= 1 &&
            tripDestinations.map((item) => (
              <SearchMenuListItem
                key={item.value}
                searchItem={item}
                onClick={onLocationChange}
              />
            ))}
          {searchPhrase.length <= 1 &&
            popularDestinations.map((item) => (
              <SearchMenuListItem
                key={item.value}
                searchItem={item}
                onClick={onLocationChange}
              />
            ))}
          {isLoading && <LoadingIndicator inline />}
        </ModalContent>
      </ModalBody>
    </>
  )
}

export default SearchPlace
