import { rem } from 'polished'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import {
  CreateBusItemViewModel,
  CreateFerryItemViewModel,
  CreateOtherTransportItemViewModel,
  CreateTrainItemViewModel,
} from './models'
import {
  CreateItemProps,
  createFerryPayloadFromViewModel,
  createOtherTransportPayloadFromViewModel,
  createTrainPayloadFromViewModel,
  createBusPayloadFromViewModel,
} from './utils'
import { formOptions } from '../Common/Fields/config'
import TransportFields from '../Common/TransportFields'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import LineBusIcon from 'components/Luxkit/Icons/line/LineBusIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LineSubwayIcon from 'components/Luxkit/Icons/line/LineSubwayIcon'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import Group from 'components/utils/Group'
import { useCreateTripItem } from 'tripPlanner/hooks/api/tripItem'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

const ChipContainer = styled(Group)`
  margin-bottom: ${rem(16)};
  margin-top: ${rem(20)};
`

type CreateTransportItemViewModel =
  | CreateTrainItemViewModel
  | CreateBusItemViewModel
  | CreateOtherTransportItemViewModel
  | CreateFerryItemViewModel

const SELECT_OPTIONS: Array<{
  value: CreateTransportItemViewModel['type']
  label: string
  icon?: React.ReactElement
}> = [
  { value: 'OTHER_TRANSPORT', label: 'Private Transport' },
  { value: 'TRAIN', label: 'Train', icon: <LineSubwayIcon /> },
  { value: 'BUS', label: 'Bus', icon: <LineBusIcon /> },
  { value: 'FERRY', label: 'Ferry', icon: <LineShipIcon /> },
]

function CreateTransport(props: CreateItemProps) {
  const { tripId, defaultDate } = props

  const { closeModal, goBack } = useModalCallbacks()

  const methods = useForm<CreateTransportItemViewModel>({
    ...formOptions,
    defaultValues: {
      type: 'OTHER_TRANSPORT',
      sourceType: 'NA',
      isBooked: false,
      isEndDateSame: true,
      startDate: defaultDate,
      endDate: defaultDate,
      startPlace: {},
      endPlace: {},
    },
  })
  const { handleSubmit, watch, setValue } = methods

  const type = watch('type')

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: () => {
      closeModal()
    },
  })

  const createTransportItem = useCallback(
    (viewModel: CreateTransportItemViewModel) => {
      switch (viewModel.type) {
        case 'TRAIN':
          createMutate({
            tripId,
            tripItem: createTrainPayloadFromViewModel(viewModel),
          })
          break
        case 'BUS':
          createMutate({
            tripId,
            tripItem: createBusPayloadFromViewModel(viewModel),
          })
          break
        case 'OTHER_TRANSPORT':
          createMutate({
            tripId,
            tripItem: createOtherTransportPayloadFromViewModel(viewModel),
          })
          break
        case 'FERRY':
          createMutate({
            tripId,
            tripItem: createFerryPayloadFromViewModel(viewModel),
          })
          break
      }
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add transport"
        onBackButtonClick={goBack}
        onCloseButtonClick={closeModal}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <ChipContainer
              direction="horizontal"
              verticalAlign="center"
              gap={8}
              wrap="wrap"
            >
              {SELECT_OPTIONS.map((option) => (
                <FilterChip
                  key={option.value}
                  startIcon={option.icon}
                  onClick={() => setValue('type', option.value)}
                  selected={type === option.value}
                  size="medium"
                >
                  {option.label}
                </FilterChip>
              ))}
            </ChipContainer>
            <TransportFields tripId={tripId} />
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createTransportItem),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>

      {isLoading && <LoadingIndicator floating />}
    </>
  )
}

export default CreateTransport
