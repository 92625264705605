import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CreateTourItemViewModel } from './models'
import { CreateItemProps, createTourPayloadFromViewModel } from './utils'
import { formOptions } from '../Common/Fields/config'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import TourFields from 'tripPlanner/components/TripModal/TripItems/Common/TourFields'
import { useCreateTripItem } from 'tripPlanner/hooks/api/tripItem'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

type ViewModel = CreateTourItemViewModel

function CreateTour(props: CreateItemProps) {
  const { tripId, defaultDate } = props

  const { closeModal, goBack } = useModalCallbacks()

  const methods = useForm<ViewModel>({
    ...formOptions,
    defaultValues: {
      type: 'TOUR',
      sourceType: 'NA',
      isBooked: false,
      startDate: defaultDate,
      endDate: defaultDate,
      startPlace: {},
      endPlace: {},
    },
  })
  const { handleSubmit } = methods

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: () => {
      closeModal()
    },
  })

  const createTourCruiseItem = useCallback(
    (viewModel: ViewModel) => {
      createMutate({
        tripId,
        tripItem: createTourPayloadFromViewModel(viewModel),
      })
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add tour"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <TourFields tripId={tripId} />
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createTourCruiseItem),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>

      {isLoading && <LoadingIndicator floating />}
    </>
  )
}

export default CreateTour
